import React from "react"
import PageTemplate from "../components/pageTemplate"

let Impressum = () => (
  <PageTemplate title="Impressum">
    <p>
      Information gemäß §5 E-Commerce-Gesetz, §14 Unternehmensgesetzbuch und §63
      Gewerbeordnung sowie Offenlegung laut §25 Mediengesetz:
    </p>

    <p>
      <strong>Name und Anschrift</strong>
    </p>

    <p>
      <strong>Dr. Astrid Jörg-Koutromanos</strong>
      <br />
      Markt 180/1, 8323 St.Marein bei Graz
      <br />
      Österreich
    </p>

    <p>
      Telefon: +43 (0) 3119 / 21000
      <br />
      Fax: +43 (0) 3119 / 21000 - 4<br />
      E-Mail: <a href="info@dr-joerg.at">info@dr-joerg.at</a>
      <br />
      Homepage: <a href="https://www.dr-joerg.at/">www.dr-joerg.at</a>
    </p>

    <p>
      <strong>Unternehmensgegenstand</strong>
      <br />
      Betrieb einer Arztpraxis für Allgemeinmedizin
    </p>

    <p>
      <strong>Berufsbezeichnung</strong>
      <br />
      Arzt für Allgemeinmedizin, verliehen in Österreich
    </p>

    <p>
      <strong>Anwendbare Rechtsvorschrift</strong>
      <br />
      Bundesgesetz über die Ausübung des ärztlichen Berufes
      <br />
      (ÄrzteG 1998): ersichtlich unter&nbsp;
      <a target="_blank" rel="noreferrer" href="http://www.ris.bka.gv.at">
        www.ris.bka.gv.at
      </a>
    </p>

    <p>
      <strong>Kammerzugehörigkeit</strong>
      <br />
      Ärztekammer für Steiermark, Kaiserfeldgasse 29, A-8010 Graz (
      <a target="_blank" rel="noreferrer" href="https://www.aekstmk.or.at/">
        www.aekstmk.or.at
      </a>
      )<br />
      Österreichische Ärztekammer, Weihburggasse 10-12, A-1010 Wien (
      <a target="_blank" rel="noreferrer" href="https://www.aerztekammer.at/">
        www.aerztekammer.at
      </a>
      )
    </p>

    <p>
      <strong>Für den Inhalt verantwortlich</strong>
      <br />
      Dr. Astrid Jörg-Koutromanos
    </p>

    <p>
      <strong>Konzept, Design und Realisation</strong>
      <br />
      Florian Jörg,{" "}
      <a target="_blank" rel="noreferrer" href="https://www.fjoerg.com">
        fjoerg IT
      </a>
    </p>

    <p>
      <strong>Fotos</strong>
      <br />
      Dr. Astrid Jörg-Koutromanos / Florian Jörg,{" "}
      <a target="_blank" rel="noreferrer" href="https://www.fjoerg.com">
        fjoerg IT
      </a>
    </p>

    <p>&nbsp;</p>

    <p>
      <strong>Haftungsausschluss:</strong> Wir übernehmen keinerlei Gewähr für
      die Aktualität, Korrektheit und Vollständigkeit der bereitgestellten
      Informationen auf dieser Website.
    </p>

    <p>
      Haftungsansprüche, die sich auf Schäden materieller oder ideeller Art
      beziehen, die durch die Nutzung oder Nichtnutzung der bereitgestellten
      Informationen verursacht wurden, sind grundsätzlich ausgeschlossen. Alle
      Angebote sind freibleibend und unverbindlich. Die Autoren behalten es sich
      ausdrücklich vor, Teile der Website oder das gesamte Angebot ohne
      gesonderte Ankündigung zu verändern, zu ergänzen, zu löschen oder die
      Veröffentlichung zeitweise oder endgültig einzustellen.
    </p>

    <p>
      <strong>Verweise und Links:</strong> Wir haben keinen Einfluss auf den
      Inhalt anderer Homepages, auf die externe Links verweisen. Wir übernehmen
      keine Haftung hinsichtlich Richtigkeit, Aktualität oder eventueller
      rechtswidriger Inhalte beziehungsweise Datenschutzbestimmungen dieser
      anderen Homepages. Ebenso kann aufgrund der Schnelllebigkeit des Mediums
      das Funktionieren einzelner Links nicht garantiert werden.
    </p>

    <p>Links auf diese Website sind selbstverständlich erwünscht.</p>
  </PageTemplate>
)

export default Impressum
